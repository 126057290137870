<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? "Agregar nueva" : "Actualizar" }} cafetería</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!-- Institution Image -->
        <!-- Upload -->
        <div class="upload-img mt-5" v-if="!dataImg">
          <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
          <vs-button @click="$refs.uploadImgInput.click()">Subir imágen</vs-button>
          <vs-alert v-show="image_error" color="danger" class="mt-5" style="height: auto !important" icon-pack="feather" icon="icon-info">
            {{ image_error }}
          </vs-alert>
        </div>
        <template v-if="dataImg">

          <!-- Image Container -->
          <div class="img-container w-64 mx-auto flex items-center justify-center">
            <img :src="dataImg" alt="img" class="responsive">
            <span class="text-danger text-sm" v-show="errors.has('product_image')">{{ errors.first('product_image') }}</span>
          </div>

          <!-- Image upload Buttons -->
          <div class="modify-img flex justify-between mt-5">
            <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*" name="product_image">
            <vs-button class="mr-4" type="flat" @click="$refs.updateImgInput.click()">Actualizar imágen</vs-button>
          </div>
          <vs-alert v-show="image_error" color="danger" style="height: initial" icon-pack="feather" icon="icon-info">
            {{ image_error }}
          </vs-alert>
        </template>

        <!-- NAME -->
        <vs-input label="Nombre de la Cafetería" autocomplete="off" v-model="dataName" class="mt-5 w-full" name="item-name" v-validate="'required|max:40'" data-vv-as="Nombre de la Cafetería"/>
        <span class="text-danger text-sm" v-show="errors.has('item-name')">{{ errors.first('item-name') }}</span>

        <!-- ADDRESS -->
        <vs-input
          icon-pack="feather"
          icon="icon-package"
          label="Dirección"
          v-model="dataAddress"
          v-validate="'required|max:100'"
          class="mt-5 w-full"
          name="item-address"
          data-vv-as="Dirección"/>
        <span class="text-danger text-sm" v-show="errors.has('item-address')">{{ errors.first('item-address') }}</span>

        <!-- TEL -->
        <vs-input
          icon-pack="feather"
          icon="icon-package"
          label="Teléfono"
          v-model="dataTel"
          v-validate="'max:100'"
          class="mt-5 w-full"
          name="item-tel"
          data-vv-as="Teléfono"/>
        <span class="text-danger text-sm" v-show="errors.has('item-tel')">{{ errors.first('item-tel') }}</span>

        <!-- STUDENTS -->
        <vs-input
          icon-pack="feather"
          icon="icon-package"
          label="Total Estudiantes"
          v-model="dataStudents"
          class="mt-5 w-full"
          v-validate="{ required: true, regex: /\d+(\.\d+)?$/, max_value:99999, min_value: 0 }"
          name="item-students"
          data-vv-as="Total Estudiantes"/>
        <span class="text-danger text-sm" v-show="errors.has('item-students')">{{ errors.first('item-students') }}</span>

        <!-- OWNER -->
        <div class="mt-5">
          <label class="vs-input--label">Administrador de la cafetería</label>
          <v-select browserAutocomplete="off" autocomplete="off" v-model="dataIdOwner" :reduce="dataIdOwner => dataIdOwner.value" :options="owner_choices" :clearable="false" v-validate="'required'" name="item-id_owner" :dir="$vs.rtl ? 'rtl' : 'ltr'" data-vv-as="Administrador"/>
          <!-- <v-select :clearable="false" :options="owner_choices" @input="updateOwner($event)" v-validate="'required'" name="item-id_owner" :dir="$vs.rtl ? 'rtl' : 'ltr'" /> -->
          <span class="text-danger text-sm" v-show="errors.has('item-owner')">{{ errors.first('item-id_owner') }}</span>
        </div>

        <!-- SCHOOL -->
        <div class="mt-5">
          <label class="vs-input--label">Escuela</label>
          <v-select browserAutocomplete="off" autocomplete="off" v-model="dataIdSchool" :reduce="dataIdSchool => dataIdSchool.value" :options="school_choices" :clearable="false" v-validate="'required'" name="item-id_school" :dir="$vs.rtl ? 'rtl' : 'ltr'" data-vv-as="Escuela"/>
          <span class="text-danger text-sm" v-show="errors.has('item-school')">{{ errors.first('item-id_school') }}</span>
        </div>
        <!-- <vs-select v-model="dataIdOwner" label="Administrador de la cafetería" class="mt-5 w-full" name="item-id_owner" v-validate="'required'">
          <vs-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in owner_choices" />
        </vs-select> -->

<!--AGREGAR V-MODEL y LABEL-->
<!-- <label class="vs-input--label">"Administrador de la cafetería" </label> -->
<!--    <v-select v-model="dataIdOwner" :options="owner_choices" class="mt-5 w-full" name="item-id_owner" v-validate="'required'">
          <v-select-item :key="item.value" :value="item.value" :text="item.text" v-for="item in owner_choices" />
       </v-select> -->
        <!-- <span class="text-danger text-sm" v-show="errors.has('item-id_owner')">{{ errors.first('item-id_owner') }}</span> -->

        <div class="mt-5 w-full" v-show="dataIdOwner">
          <label class="vs-input--label">Comisión Tiptap</label>
          <div class="flex flex-wrap">
            <vs-input-number
              @input="checkLength($event)"
              label="%"
              v-model="dataTiptapCommission"
              :step="0.25"
              name="tiptap_commission"
              v-validate="{ regex: /\d+(\.\d+)?$/, min_value: 0, max_value: 100, decimal: true }"
              data-vv-as="Comisión Tiptap"
            />
            <span class="text-danger text-sm"  v-show="errors.has('tiptap_commission')">{{ errors.first('tiptap_commission') }}</span>
          </div>
        </div>

      </div>
    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid" id="saveButton">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
/* import "vue-select/dist/vue-select.css"; */
// import moduleDataListInstitutions from '@/store/data-list-institutions/moduleDataList.js'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    isSidebarActive(val) {
      if(!val) return
      if(Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        let { id, image, name, id_owner, address, tel, students, tiptap_commission, id_school } = JSON.parse(JSON.stringify(this.data))
        this.dataId = id
        this.dataImg = this.$baseUrlImg + image
        this.dataName = name
        this.dataIdOwner = id_owner
        this.owner = id_owner || null
        this.dataAddress = address
        this.dataTel = tel
        this.dataStudents = students
        this.dataTiptapCommission = tiptap_commission || 0
        this.dataIdSchool = id_school
        this.image_loaded = false
        this.image_error = ''
        this.getOwnerChoices()
        this.getSchoolChoices()
        this.initValues()
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  data() {
    return {

      dataId: null,
      dataImg: null,
      dataName: "",
      dataIdOwner: null,
      dataAddress: '',
      dataTel: '',
      dataStudents: 0,
      dataTiptapCommission: 1.50,
      dataIdSchool: null,

      owner: null,

      product_image: {
        size: '',
        width: '',
        height: ''
      },

      image_loaded: false,
      image_error: '',

      owner_choices: [],
      school_choices: [],

      settings: { // perfectscrollbar settings
          maxScrollbarLength: 60,
          wheelSpeed: .60,
      },
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if(!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    isFormValid() {
       return !this.errors.any() && this.dataName && this.dataAddress && this.image_error == '' && this.dataIdOwner && this.dataImg
    },

    //console: () => console,
  },
  methods: {
    initValues() {
      if(this.data.id) return
        this.dataId = null
        this.dataImg = null
        this.dataName = ""
        this.dataIdOwner = null
        this.dataAddress = ""
        this.dataTel = ""
        this.dataStudents = 0
        this.dataTiptapCommission = 0
        this.dataIdSchool = null

        this.image_loaded = false
        this.image_error = ''
        this.getOwnerChoices()
        this.getSchoolChoices()
    },
    submitData() {
        this.$validator.validateAll().then(result => {
          if (result) {
            this.disableSaveButton();
            // If owner was changed, check if has pending payments
            if (this.owner !== null && this.owner != this.dataIdOwner) {
              this.$store.dispatch(`dataListInstitutions/getPendingPayments`, this.owner)
                .then(response => {
                  if(response.length) {
                    let pending = response.filter(i => i.id_institution == this.dataId);
                    if(pending.length && pending[0].total_pay != 0) {
                      this.enableSaveButton();
                      this.showError("Hay saldos pendientes de pago. Debe realizar la solicitud de pagos pendientes antes de realizar el cambio.");
                    } else {
                      this.addUpdateData();
                    }
                  } else {
                    this.addUpdateData();
                  }
                })
                .catch(error => {this.showError(error)});
            } else {
              this.addUpdateData();
            }

          }
        })
    },
    disableSaveButton() {
      this.loading = true
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#saveButton",
        scale: 0.45
      })
    },
    enableSaveButton() {
      this.loading = false
      this.$vs.loading.close("#saveButton > .con-vs-loading")
    },
    addUpdateData() {
      const obj = {
        id: this.dataId,
        id_admin: this.$store.state.AppActiveUser.account.id,
        id_owner: this.dataIdOwner,
        id_school: this.dataIdSchool,
        name: this.dataName,
        address: this.dataAddress,
        tel: this.dataTel || '',
        image: (this.image_loaded) ? this.dataImg : '',
        students: this.dataStudents,
        tiptap_commission: this.dataTiptapCommission
      }

      let action = ((this.dataId !== null && this.dataId >= 0) ? "updateInstitution" : "addInstitution");

      this.$store.dispatch(`dataListInstitutions/${action}`, obj)
        .then(response => {
          if(!response.data.status) {
            throw new Error(response.data.msg);
          }
          this.enableSaveButton()
          this.showAddSuccess()
          this.$emit('closeSidebar');
          this.initValues();
        })
        .catch(error => {
          this.enableSaveButton()
          this.showError(error)
        })
    },
    updateCurrImg(input) {
      this.image_loaded = false;
      this.image_error = '';
      if (input.target.files && input.target.files[0]) {
        this.product_image.size = input.target.files[0].size;
        var reader = new FileReader()
        reader.onload = e => {
          let img = new Image();
          img.onload = () => {
            this.product_image.width = img.width;
            this.product_image.height = img.height;
            // console.log(this.product_image.size);
            if (this.product_image.width != this.product_image.height || this.product_image.width > 300 ) {
              this.image_error = 'La imágen debe ser cuadrada (misma altura y ancho) con un máximo de 300px';
              // console.log(this.image_error);
            } else {
              if (this.product_image.size > 100000){
                this.image_error = 'La imágen excede el tamaño máximo (100 KB).';
              } else {
                this.dataImg = e.target.result
                this.image_loaded = true;
              }
            }

          }
          img.src = e.target.result;
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    updateOwner(event) {
      this.dataOwner = event.value
    },
    getOwnerChoices() {
      if(this.owner_choices && this.owner_choices.length) return

      const owners = this.$store.getters['userManagement/getCoffeshopOwners'];
      owners.forEach(element => {
        this.owner_choices.push({label: element.full_name, value: element.id})
      });
    },
    getSchoolChoices() {
      if(this.school_choices && this.school_choices.length) return

      const schools = this.$store.getters['dataListInstitutions/getSchools'];
      schools.forEach(element => {
        this.school_choices.push({label: element.name, value: element.id})
      });
    },
    showError(msg) {
      this.$vs.notify({
        color: 'danger',
        title: 'Error',
        text: msg || 'No se pudo completar el proceso.',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-alert-circle',
      })
    },
    showAddSuccess() {
      this.$vs.notify({
        color: 'success',
        title: 'Cafetería',
        text: 'Se han guardado los cambios.',
        time: 8000,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-check',
      })
    },
    checkLength(value) {
      if(value < 0 || value == '')
        value = 0

      this.dataTiptapCommission = (typeof value == 'string') ? parseFloat(value).toFixed(3) : value.toFixed(3);
    },
  },
  components: {
    VuePerfectScrollbar,
    'v-select': vSelect,
 /*    vSelect, */
  },
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
    p {
      margin-bottom: 2rem;
      > span {
        font-size: 0.9rem;
      }
    }
  }
}

.scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
